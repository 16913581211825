import React from "react"
import { FaFacebook, FaTwitterSquare, FaInstagramSquare, FaYoutube } from "react-icons/fa"
export default [
  {
    icon: <FaFacebook />,
    url: "https://www.facebook.com/linda.letheby.9",
  },
  {
    icon: <FaTwitterSquare />,
    url: "https://twitter.com/lees_Art",
  },
  {
    icon: <FaInstagramSquare />,
    url: "https://www.instagram.com/gardenhomeschool/",
  },
  {
    icon: <FaYoutube />,
    url: "https://www.youtube.com/channel/UCxjuQsvfmTE7DIwCdcdPc_A",
  },
]
